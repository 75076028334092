import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Input, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import CategoryDelete from "./ReviewDelete";
import ReviewStatus from "./ReviewStatus";
import { getCoupenDatafetch } from "../Controller/Review";
import { FiEdit, FiTrash } from 'react-icons/fi'; // Import edit and delete icons
import Switch from "react-switch"; // Import react-switch

import moment from 'moment';
import "./Review.css";

function ListReviewData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [itemIdToChange, setItemIdToChange] = useState(null);
  const [statusChange, setStatusChange] = useState(null);
  const [popUpOpenChange, setPopUpOpenChange] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [categoryList, setCategoryData] = useState([]);

  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getCategoryData();
  };

  const getCategoryData = async (page, coupon_code) => {
    const options = {
      page: page,
      coupon_code: coupon_code,
    };
    try {
      const result = await getCoupenDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.review;
        const totalCount = result.data.response.result.totalCount;
        setCategoryData(responseData);
        setTotalItems(totalCount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const wordCount = (str) => {
    return str ? str.trim().split(/\s+/).length : 0;
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getCategoryData(page);
  };

  const togglePopUpChange = (itemId, checked) => {
    setPopUpOpenChange(!popUpOpenChange);
    setItemIdToChange(itemId);
    // setStatusChange(status === "A" ? "I" : "A");
    setStatusChange(checked ? "A" : "I"); // Use checked to determine the status
    getCategoryData();
  };

  const handleViewDetail = (item) => {
    navigate('/reviewDetail', { state: { selectedItem: item } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategoryData(currentPage, searchValue);
  }, [currentPage, searchValue]);

  const dropdownMenuItems = (itemId, status, item) => [
    {
      key: '1',
      label: (
        <div onClick={() => handleViewDetail(item)}>
          <FaEdit />
          <span className="show_span_edit">View Detail</span>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={() => togglePopUpChange(itemId, status)}>
          <FaEdit />
          <span className="show_span_edit">
            {status === "A" ? "Not Approved" : "Approved"}
          </span>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div onClick={() => togglePopUp(itemId)}>
          <MdDelete />
          <span className="show_span_edit">Delete</span>
        </div>
      ),
    },
  ];

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column all_product_hidden_edit">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Reviews List</h1>
                <p className="mb-4"></p>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold">
                      Reviews List Table
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Product Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Description</th>
                            <th>Review Date</th>
                            <th>Review Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categoryList.length > 0 ? (
                            categoryList.map((item, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * 10 + index + 1}</td>
                                {/* <td style={{ textTransform: 'capitalize' }}>{item.products_id.product_name}</td> */}
                                <td style={{ textTransform: 'capitalize' }}>
                                  {item.products_id && item.products_id.product_name
                                    ? item.products_id.product_name
                                    : 'N/A'}
                                </td>
                                <td style={{ textTransform: 'capitalize' }}>{item.name}</td>
                                <td>{item.email}</td>
                                <td style={{ textTransform: 'capitalize' }}>
                                  {wordCount(item.description) > 50 ? (
                                    <textarea
                                      className="scrollable-description"
                                      value={item.description}
                                      readOnly
                                    />
                                  ) : (
                                    item.description
                                  )}
                                </td>
                                <td>{moment(item.date_time).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                {/* <td style={{ color: item.status === "A" ? "green" : "red" }}>
                                  {item.status === "A" ? "Approved" : "Not Approved"}
                                </td> */}
                                <td>
                                  <Switch className="all_switch_button"
                                  checked={item.status === "A"}
                                  onChange={(checked) => togglePopUpChange(item._id, checked)}
                                  offColor="#f44336" 
                                  onColor="#4caf50" 
                                  offHandleColor="#fff" 
                                  onHandleColor="#fff" 
                                />
                                </td>
                                <td>
                                <FiEdit
                                    style={{ cursor: 'pointer', color: '#4caf50' }} 
                                    onClick={() => handleViewDetail(item)}
                                  />
                                    <FiTrash
                                      style={{ cursor: 'pointer', color: '#f44336' }} // Styling for the Delete icon
                                      onClick={() => togglePopUp(item._id)} // Function to handle the delete action
                                    />
                                
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="all_pagination">
                      <p>Show {((currentPage - 1) * 10) + 1} to {Math.min(currentPage * 10, totalItems)} of {totalItems}</p>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={handlePageChange}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoryDelete
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      />
      <ReviewStatus
        popUpOpenChange={popUpOpenChange}
        togglePopUpChange={togglePopUpChange}
        itemIdToChange={itemIdToChange}
        status={statusChange}
      />
    </div>
  );
}

export default ListReviewData;
