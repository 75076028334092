import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { imageURL } from '../Config/constant';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function OrderDetail() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const location = useLocation();
  const selectedItem = location.state.selectedItem;
  const addressfull = selectedItem.orderdetails[0].address;
  console.log(selectedItem);
  console.log("addressfull",addressfull);
  const subTotal = selectedItem.orderdetails.reduce((total, item) => {
    const colorSizeDetail = item.product_id.color_size_details.find(
        (colorSize) => colorSize.color_code === item.color
    );
    // const mrpPrice = colorSizeDetail ? colorSizeDetail.mrp_price : 0; // Default to 0 if colorSizeDetail not found
    const mrpPrice = item.price; // Default to 0 if colorSizeDetail not found
    console.log(mrpPrice);
    return total + (mrpPrice);
    
}, 0);
 
  // const gstAmount = selectedItem.orderdetails.reduce((total, item) => total  * item.qty, 0);

 
  // const deliveryCharges = selectedItem.delivery_charges || 0;
  // const grandTotal = subTotal + gstAmount + deliveryCharges;
 

  const gstAmount = selectedItem.orderdetails.reduce((total, item) => {
    const itemGST = item.price  * item.qty;
    return itemGST;
  }, 0);
  const gstamount = selectedItem.gst;
  // Additional 8% GST on the sub total
  const gstString = selectedItem.gst; // "9 %"
  const gstDecimal = parseFloat(gstString) / 100; // Converts "9 %" to 0.09

  // const extraGstAmount = subTotal * 0.08;
   const extraGstAmount = subTotal * gstDecimal;
  const totalGstAmount = subTotal + extraGstAmount; // Add extra 8% GST to the original GST amount
  
  // Calculate extra discount amount based on the subtotal
  let extraDiscountAmount = 0;

// Check if the first item has a discount
if (selectedItem.orderdetails.length > 0 && selectedItem.orderdetails[0].discount) {
const discountValue = parseFloat(selectedItem.orderdetails[0].discount.replace('%', '').trim());

// Calculate the extra discount amount based on the subtotal
extraDiscountAmount = subTotal * (discountValue / 100);
}


let extrarewardAmount = 0;

if (selectedItem.orderdetails.length > 0 && selectedItem.orderdetails[0].rewardpoint) {
extrarewardAmount = selectedItem.orderdetails[0].rewardpoint / 100; // Convert reward points to the desired format
}



  let extraDiscountAmount1 = 0;
  if (selectedItem.orderdetails.length > 0 && selectedItem.orderdetails[0].discount) {
    extraDiscountAmount1 = selectedItem.orderdetails[0].discount;
  }
  
  // Calculate Grand Total (including Sub Total, total GST Amount, extra discount, and Delivery Charges)
  const deliveryCharges = parseFloat(selectedItem.shipping_charge) || 0;

  const grandTotal = subTotal + extraGstAmount  - extraDiscountAmount + deliveryCharges - extrarewardAmount;

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    
    // Split the string into words
    const words = string.split(' ');
    
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    
    // Join the words back into a single string
    return capitalizedWords.join(' ');
  };
  
 
  const handleViewInvoice = () => {
    const image = "https://imageluar.s3.ap-south-1.amazonaws.com/image/luar+image2.png";
    const invoiceElement = document.createElement('div');
    invoiceElement.style.width = '900px';
  invoiceElement.style.margin = '0 auto';
  invoiceElement.style.padding = '20px';
  invoiceElement.style.background = '#f9f4f1';

    const subTotal = selectedItem.orderdetails.reduce((total, item) => {
      const colorSizeDetail = item.product_id.color_size_details.find(
          (colorSize) => colorSize.color_code === item.color
      );
      // const mrpPrice = colorSizeDetail ? colorSizeDetail.mrp_price : 0; 
      const mrpPrice = item.price; 
      return total + (mrpPrice);
  }, 0);
   
    // const gstAmount = selectedItem.orderdetails.reduce((total, item) => total  * item.qty, 0);

   
    // const deliveryCharges = selectedItem.delivery_charges || 0;
    // const grandTotal = subTotal + gstAmount + deliveryCharges;
   

    const gstAmount = selectedItem.orderdetails.reduce((total, item) => {
      const itemGST = item.price  * item.qty;
      return itemGST;
    }, 0);
    const gstamount = selectedItem.gst;
    // Additional 8% GST on the sub total
    const gstString = selectedItem.gst; // "9 %"
    const gstDecimal = parseFloat(gstString) / 100; // Converts "9 %" to 0.09

    // const extraGstAmount = subTotal * 0.08;
     const extraGstAmount = subTotal * gstDecimal;
    const totalGstAmount = subTotal + extraGstAmount; // Add extra 8% GST to the original GST amount
    
    // Calculate extra discount amount based on the subtotal
    let extraDiscountAmount = 0;

// Check if the first item has a discount
if (selectedItem.orderdetails.length > 0 && selectedItem.orderdetails[0].discount) {
  const discountValue = parseFloat(selectedItem.orderdetails[0].discount.replace('%', '').trim());
  
  // Calculate the extra discount amount based on the subtotal
  extraDiscountAmount = subTotal * (discountValue / 100);
}


let extrarewardAmount = 0;

if (selectedItem.orderdetails.length > 0 && selectedItem.orderdetails[0].rewardpoint) {
  extrarewardAmount = selectedItem.orderdetails[0].rewardpoint / 100; // Convert reward points to the desired format
}



    let extraDiscountAmount1 = 0;
    if (selectedItem.orderdetails.length > 0 && selectedItem.orderdetails[0].discount) {
      extraDiscountAmount1 = selectedItem.orderdetails[0].discount;
    }
    
    // Calculate Grand Total (including Sub Total, total GST Amount, extra discount, and Delivery Charges)
    const deliveryCharges = parseFloat(selectedItem.shipping_charge) || 0;

    const grandTotal = subTotal + extraGstAmount  - extraDiscountAmount + deliveryCharges - extrarewardAmount;
     
 
     const invoiceContent = selectedItem.orderdetails.map((item, index) => {
      const colorSizeDetail = item.product_id.color_size_details.find(
          (colorSize) => colorSize.color_code === item.color
      );
    
      return `
          <tr>
              <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 100px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${index + 1}</td>
              <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 200px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${capitalizeFirstLetter(item.product_name)} (${item.productvariant}</td>
              <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${item.product_id.hsn}</td>
              <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${item.qty}</td>
              <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">INR ${colorSizeDetail ? colorSizeDetail.mrp_price : 'N/A'}</td>
              <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">INR ${item.price}</td>
          </tr>
      `;
  }).join('');
    invoiceElement.innerHTML = `
     <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Invoice</title>
    <style>
      @font-face {
        font-family: "hermitage-regular";
        src: url("/fonts//Hermitage\ Regular.otf");
      }

      @font-face {
        font-family: "quicksand-bold";
        src: url("/fonts//Quicksand-Bold.ttf");
      }

      @font-face {
        font-family: "quicksand-medium";
        src: url("/fonts/Quicksand-Medium.ttf");
      }

      @font-face {
        font-family: "quicksand-light";
        src: url("/fonts/Quicksand-Light.ttf");
      }

      @font-face {
        font-family: "quicksand-semiBold";
        src: url("/fonts/Quicksand-SemiBold.ttf");
      }

      @font-face {
        font-family: "quicksand-regular";
        src: url("/fonts/Quicksand-Regular.ttf");
      }
    </style>
  </head>
  <body
    style="
      font-family: Arial, sans-serif;
      font-size: 14px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      background: #fff;
    "
  >
    <div
      style="
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        padding: 20px;
        background: #f9f4f1;
      "
    >
      <table style="width: 100%; margin-bottom: 20px; margin-top: 40px">
        <tr>
          <td
            style="
              width: 50%;
              vertical-align: top;
              font-family: 'Times New Roman', serif;
            "
          >
            <div>
              <img
               src="${image}"
                alt="Luar Logo"
                style="max-width: 165px"
              />
              <h2
                style="
                  margin: 0;
                  font-size: 25px;
                  margin-bottom: 20px;
                  font-family: hermitage-regular;
                  color: #765c6b;
                "
              >
                TAX INVOICE
              </h2>
            </div>
            <span
              style="
                font-family: quicksand-bold;
                color: #765c6b;
                font-size: 18px;
              "
            >
              SOLD BY: ESSCEE BEAUTY PRIVATE LIMITED
            </span>
            <br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
              ;
              >806, DLH Park, Near MTNL Telephone Exch,<br />
              S.V Road, Goregaon West- 400104<br />
              Mumbai, Maharashtra, India.<br />
              <br />
              GSTIN: 27AAHCE2432N1ZG</span
            >
          </td>
          <td
            style="
              width: 50%;
              vertical-align: top;
              font-family: 'Times New Roman', serif;
            "
          >
            <span
              style="
                font-family: quicksand-bold;
                color: #765c6b;
                font-size: 18px;
              "
              >BILL TO: ${capitalizeFirstLetter(selectedItem.firstName)} ${capitalizeFirstLetter(selectedItem.lastName)}  </span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
            >
              Delivery Address: ${capitalizeFirstLetter(selectedItem.orderdetails[0].address)} - ${selectedItem.pincode}</span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
              >Billing Address: ${capitalizeFirstLetter(selectedItem.orderdetails[0].address)} - ${selectedItem.pincode} </span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
              >Phone:${selectedItem.phone}</span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
              >Email:${selectedItem.email}</span
            ><br />
            <br />
            <br />
            <br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
              >Payment Method:${selectedItem.payment_mode}</span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #765c6b;
                font-family: quicksand-medium;
              "
              >Delivery Method:</span
            >
          </td>
        </tr>
      </table>
      <table style="width: 100%; margin-bottom: 20px">
        <tr>
          <td
            style="
              width: 50%;
              font-size: 18px;
              color: #765c6b;
              font-family: quicksand-medium;
            "
          >
            Invoice Date:${new Date(selectedItem.created_date).toLocaleDateString()}
          </td>
          <td
            style="
              width: 50%;
              font-size: 18px;
              color: #765c6b;
              font-family: quicksand-medium;
            "
          >
            Order Number:${selectedItem.order_id}
          </td>
        </tr>
        <tr>
          <td
            style="
              font-size: 18px;
              color: #765c6b;
              font-family: quicksand-medium;
            "
            ;
          >
            Invoice Number:${selectedItem.order_id}
          </td>
          <td
            style="
              font-size: 18px;
              color: #765c6b;
              font-family: quicksand-medium;
            "
          >
            Order Date:${new Date(selectedItem.created_date).toLocaleDateString()}
          </td>
        </tr>
      </table>
      <div style="text-align: center; margin-top: 28px; margin-bottom: 0px">
        <span
          style="
            font-size: 25px;
            font-family: 'hermitage-regular';
            color: #785f6e;
            text-align: center;
          "
          >ORDER SUMMARY</span
        >
      </div>

      <div style="border: 1px solid #ddd;
      border-radius: 20px;
      overflow: hidden;">
        <table
          style="width: 100%; border-collapse: collapse;"
        >
          <thead>
            <tr>
              <th
                style="
                  padding: 15px;
                  background: #765c6b;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #cbc2ca;
                  /* border-top-left-radius: 10px; */
                "
              >
                #
              </th>
              <th
                style="
                  padding: 15px;
                  background: #765c6b;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #cbc2ca;
                "
              >
                Item Description
              </th>
              <th
                style="
                  padding: 15px;
                  background: #765c6b;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #cbc2ca;
                "
              >
                HSN Code
              </th>
              <th
                style="
                  padding: 15px;
                  background: #765c6b;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #cbc2ca;
                "
              >
                Qty
              </th>
              <th
                style="
                  padding: 15px;
                  background: #765c6b;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #cbc2ca;
                "
              >
                Unit Price
              </th>
             
              <th
                style="
                  padding: 15px;
                  background: #765c6b;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #cbc2ca;
                  /* border-top-right-radius: 10px; */
                "
              >
                Total Amount
              </th>
            </tr>
          </thead>
            <!-- Repeat this <tr> for each item -->
            <tbody>
                <!-- Dynamically populate order details -->
               ${invoiceContent}
                 <tr>
              <td style="padding: 5px; padding-top: 50px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="5">Sub Total:</td>
              <td style="padding: 5px; padding-top: 50px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${subTotal.toFixed(2)}</td>
            </tr>
              <!-- GST Amount (18%) row -->
<tr>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="5">GST Amount (${gstamount}):</td>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${extraGstAmount.toFixed(2)}</td>
            </tr>
             <!-- GST Amount (18%) row -->
             
<tr>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="5">Discount( ${extraDiscountAmount1}):</td>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${extraDiscountAmount.toFixed(2)}</td>
            </tr>
            <!-- Delivery Charges row -->
            <tr>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="5">Reward:</td>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';">INR ${extrarewardAmount.toFixed(2)}</td>
            </tr>
            <tr>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="5">Delivery Charges:</td>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';">INR ${deliveryCharges.toFixed(2)}</td>
            </tr>
            <!-- Grand Total row -->
            <tr>
              <td style="padding: 5px; padding-bottom: 50px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="5">Grand Total:</td>
              <td style="padding: 5px; padding-bottom: 50px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${grandTotal.toFixed(2)}</td>
            </tr>
              </tbody>
           
        </table>
      </div>
      <div
        style="
          text-align: center;
          margin-top: 10px;
          font-size: 12px;
          margin-bottom: 35px;
        "
      >
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #785f6e;
          "
        >
          Kind Regards, Luar Customer Service | Need help? Contact us at +91
          8691 384848 or hello@luar.co.in</span
        ><br />
        <br />
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #785f6e;
          "
        >
          Corporate Office: 501, X Cube building, off New Link Rd, Veera Desai
          Industrial Estate,</span
        ><br />
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #785f6e;
          "
          >Andheri West, Mumbai- 400102.</span
        ><br />
        <br />
        <br />
        <br />
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #785f6e;
            font-weight: 900;
          "
        >
          www.luarbeauty.in</span
        >
      </div>
    </div>
  </body>
</html>

    `;

    document.body.appendChild(invoiceElement);

    html2canvas(invoiceElement).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the PDF in a new tab
        window.open(pdfUrl, '_blank');

        // Clean up: Remove the temporary invoiceElement
        document.body.removeChild(invoiceElement);
    }).catch((error) => {
        console.error('Error generating PDF:', error);
    });

};


  
  if (!selectedItem) {
    return <div>Loading...</div>;
  }
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Order  Details</h5>
                  </div>
                 
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <div
                      className="my_add_event_form_field"
                      style={{ float: "right" }}
                    >
                      <Link to="/orderList">
                        <button type="button" className="btn btn-primary">
                          Back
                        </button>
                      </Link>
                      <button type="button" className="btn btn-primary ml-2" onClick={handleViewInvoice}>
                        View Invoice
                      </button>
                    </div>
                    <div className="responsive">
                   
    <div className="form-group">
      <label htmlFor="exampleInputText">User Details :- </label>
      <h3>Name :- {capitalizeFirstLetter(selectedItem.firstName)} {capitalizeFirstLetter(selectedItem.lastName)}</h3>
      <p>Email :- {selectedItem.email}</p>
      <p>Phone: {selectedItem.phone}</p>
      <p>Shipping Address: {capitalizeFirstLetter(addressfull)}  </p>
    </div>
    <div className="form-group">
  <label htmlFor="exampleInputText">Order Details:</label>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th  scope="col">Product Image</th>
        <th  scope="col">Product Name</th>
        <th  scope="col">Color</th>
        <th  scope="col">Product Price</th>
        <th  scope="col">Quantity</th>
        <th  scope="col">Total Price</th>
        <th  scope="col">Payment Mode</th>
        <th  scope="col">Payment Status</th>
        <th  scope="col">Coupon Code</th>
        {/* <th  scope="col">Discount</th> */}
        <th  scope="col">Order Date</th>
      </tr>
    </thead>
    <tbody>
  {selectedItem.orderdetails.map((orderDetail, index) => {
    // Check if color_size_details exists and has length greater than 0
    if (orderDetail.product_id && orderDetail.product_id.color_size_details && orderDetail.product_id.color_size_details.length > 0) {
      // Find the color_size_details object where color_name matches the orderDetail color
      const colorSizeDetail = orderDetail.product_id.color_size_details.find(
        (colorSize) => colorSize.color_code === orderDetail.color
      );
      const unitPrice = orderDetail.qty > 0 ? (orderDetail.price / orderDetail.qty).toFixed(2) : orderDetail.price;
      const unitPriceqty = orderDetail.qty * unitPrice;

      return (
        <tr key={index}>
          <td>
            <img
              src={`${imageURL}${colorSizeDetail.images[0]}`}
              alt={orderDetail.product_name}
              style={{ width: '100px', height: '100px' }}
            />
          </td>
          <td>{orderDetail.product_name} ({orderDetail.productvariant})</td>
          <td>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: orderDetail.color,
                                            marginRight: '10px',
                                            border: '1px solid #000'
                                          }} 
                                        ></div>
                                        {/* {colorName} */}
                                      </div>
                                    </td>
        
          <td>{unitPrice}</td>
          <td>{orderDetail.qty}</td>
          <td>{unitPriceqty}</td>
          <td>{capitalizeFirstLetter(orderDetail.payment_mode)}</td>
          <td>{capitalizeFirstLetter(orderDetail.payment_status)}</td>
          <td>{orderDetail.coupon ? orderDetail.coupon : 'No coupon applied'}</td>
          {/* <td>{orderDetail.discount ? orderDetail.discount : 0}</td> */}
          <td>{formatDate(orderDetail.createdAt)}</td>
        </tr>
      );
    } else {
      // Handle case where color_size_details is not defined or empty
      return (
        <tr key={index}>
          <td colSpan="11">Color details not available</td>
        </tr>
      );
    }
  })}
</tbody>
</table>
<table className="table table-bordered" style={{ width: '37%', marginLeft: '679px' }}>

<tbody>
  <tr>
    <td  scope="col">
      Sub Total:
    </td>
    <td >
      INR {subTotal.toFixed(2)}
    </td>
  </tr>
  <tr>
    <td  scope="col">
      GST Amount ({gstamount}):
    </td>
    <td >
      INR {extraGstAmount.toFixed(2)}
    </td>
  </tr>
  <tr>
    <td  scope="col">
      Discount ({extraDiscountAmount1}):
    </td>
    <td >
      INR {extraDiscountAmount.toFixed(2)}
    </td>
  </tr>
  <tr>
    <td  scope="col">
      Reward:
    </td>
    <td >
      INR {extrarewardAmount.toFixed(2)}
    </td>
  </tr>
  <tr>
    <td  scope="col">
      Delivery Charges:
    </td>
    <td >
      INR {deliveryCharges.toFixed(2)}
    </td>
  </tr>
  <tr>
    <td  scope="col">
      Grand Total:
    </td>
    <td >
      INR {grandTotal.toFixed(2)}
    </td>
  </tr>
</tbody>

           
  </table>
</div>



 

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
