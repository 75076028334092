import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import { orderCancel,shippingOrderCancelData } from '../Controller/Order';
import { useParams ,useNavigate} from "react-router-dom";

const OrdercancelPopUp = ({ popUpOpenstatus, togglePopUpstatus, itemIdPasscancel}) => {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const navigate = useNavigate();
  const adminInformation = JSON.parse(adminInformationString);
  const handleCancel = () => {
    togglePopUpstatus();
  };

const handleDelete = async () => {
  const options = { ids: [itemIdPasscancel],  auth:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ3OTMzOTgsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzI1MDEwNDk1LCJqdGkiOiJ2Z1Q4Mk4wSkM1MTBSQXdtIiwiaWF0IjoxNzI0MTQ2NDk1LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcyNDE0NjQ5NSwiY2lkIjo0NDY0NjI3LCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.tgede3zlqxH4fa2ItWNwLvIo9ihbl0OlHLdy7f5F9LM"}; 
  try {
    const result = await shippingOrderCancelData(options);
    if (result) {
      const options = {
        shipment_order_id: itemIdPasscancel,
      };
      try {
        const resultcancel = await orderCancel(options, adminInformation.token);
          if (resultcancel) {
            togglePopUpstatus(); 
          }
      } catch (error) {
        console.error("Error creating order:", error);
      }
    } else {
      localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
      console.error("Unexpected response format:", result);
    }
  } catch (error) {
    console.error("Error deleting social icon data:", error);
  }
};


  return (
    <Dialog
      open={popUpOpenstatus}
      onClose={togglePopUpstatus}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirmation
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUpstatus}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you really want to cancel order?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default OrdercancelPopUp;
