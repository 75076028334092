import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getBannerDatafetch = async (options , authToken)=>{
    try{
        const {page,page_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'banner/list-banner',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

export const statusCategoryData = async (options , authToken)=>{
  try{
      const {id,status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'banner/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getBannerData = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'banner/get-banner',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
 export const addBannerformData = async (options, authToken) => {
  try {
    const { page_name, button_title, category_id, title, button_url, banner_image, bottom_banner_image } = options;

    // Create a FormData object
    const formData = new FormData();
    formData.append('page_name', page_name);
    formData.append('button_title', button_title);
    formData.append('title', title);
    formData.append('category_id', category_id);
    formData.append('button_url', button_url);

    // Handle banner_image if it's not empty
    if (banner_image && banner_image.length > 0 && banner_image[0].images) {
      banner_image[0].images.forEach((image, imageIndex) => {
        formData.append(`banner_image_${imageIndex}`, image);
      });
    }

    // Handle bottom_banner_image if it's not empty
    if (bottom_banner_image && bottom_banner_image.length > 0 && bottom_banner_image[0].images) {
      bottom_banner_image[0].images.forEach((image, imageIndex) => {
        formData.append(`bottom_banner_image_${imageIndex}`, image);
      });
    }

    const posrData = {
      url: API_ADMIN_URL + 'banner/add-banner',
      postData: formData,
      headers: {
        authToken: authToken,
        // 'Content-Type': 'multipart/form-data', // Remove this, the browser will handle it
      },
    };

    const res = await postRequest(posrData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateBannerformData = async (options, authToken) => {
    try {
      const { page_name, button_title,title,category_id, button_url,banner_image, id ,bottom_banner_image} = options;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('page_name', page_name);
      formData.append('button_title', button_title);
      formData.append('category_id', category_id);
      formData.append('title', title);
      formData.append('button_url', button_url);
      if (banner_image[0] && banner_image[0].images !== undefined) {
        banner_image[0].images.forEach((image, imageIndex) => {
          formData.append(`banner_image_${imageIndex}`, image);
        });
      } else {
        banner_image.forEach((image, imageIndex) => {
          formData.append(`banner_image_${imageIndex}`, image);
        });
      }
      if (bottom_banner_image[0] && bottom_banner_image[0].images !== undefined) {
        bottom_banner_image[0].images.forEach((image, imageIndex) => {
          formData.append(`bottom_banner_image_${imageIndex}`, image);
        });
      } else {
        bottom_banner_image.forEach((image, imageIndex) => {
          formData.append(`bottom_banner_image_${imageIndex}`, image);
        });
      }
      const postData = {
        url: API_ADMIN_URL + 'banner/edit-banner',
        postData: formData, // Pass the FormData object
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };

      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
};

 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteBannerData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'banner/delete-banner',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
export const getbrandDatafetch = async (options , authToken)=>{
  try{
      const {brand}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'banner/list-all-brand',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 